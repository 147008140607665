$black: #000000;
$white: #FFFFFF;
$light-grey: #F9F9F9;

// primary colors
$yellow: #FFD800;
$magenta: #EC407A;
$warm-grey: #F1F0E8;

// secondary colors
$purple: #AB46BE;
$light-blue: #2A9FE5;
$orange: #F4990A;
$green: #5CC13C;
$red: #E24646;
$dark-blue: #4F5FD1;

// hovers
$yellow-hover: #fcf25f;
$warm-grey-hover: #e5e4dc;

// disabled
$grey: lighten(#919191, 10%);

// Responsive breakpoints
$screen-monitor: 1600px;
$screen-desktop: 1400px;
$screen-laptop: 1200px;
$screen-laptop-small: 1024px;
$screen-tablet: 768px;
$screen-mobile: 580px;
$screen-mobile-small: 420px;
$screen-mobile-smaller: 350px;