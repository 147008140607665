@use 'settings';
@use 'typography';

html,
body {
    height: 100%;
}

body {
    font-family: $secondary-font;
    font-size: 16px;
    line-height: 24px;
    background-color: $warm-grey;
}

.c {
    clear: both;
}

.btn {
    background-color: $yellow;
    font-family: $primary-font;
    -webkit-appearance: none;
    appearance: none;
    font-size: 17px;
    line-height: 24px;
    border: 0;
    text-align: center;
    color: $black;
    cursor: pointer;
    display: inline-block;
    border-radius: 5px;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    padding: 16px 20px;
    min-width: 120px;
    margin-right: 20px;
    padding-left: 40px;
    position: relative;

    &:after {
        content: ">";
        float: left;
        display: block;
        left: 20px;
        top: 15px;
        position: absolute;
        bottom: 0;
        margin: auto;
    }

    &.btn-small {
        padding: 10px 10px;
        line-height: 18px;
    }

    &.btn-export {
        margin-left: 20px;

        &:last-child {
            margin-left: 0px;
        }
    }

    &.btn-average {
        padding: 10px 10px;
        line-height: 18px;
        text-align: left;
        font-size: 16px;
    }

    &.transition {
        transition: all 0.8s ease;
    }

    &:hover {
        background-color: $yellow-hover;
    }

    &:focus {
        transform: scale(.95)
    }

    &.secondary {
        background-color: $warm-grey;

        &:hover {
            background-color: $warm-grey;
        }
    }

    &.alternative {
        background-color: $white;

        &:hover {
            background-color: $warm-grey;
        }
    }

    &.disabled,
    &:disabled {
        background-color: $grey;
        opacity: 0.5;
        cursor: default;
    }
}


.headershape {
    position: absolute;
    top: -20px;
    right: 0;
    width: 280px;
    height: 150px;
    display: block;
    background-position: right 0px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 0 0 auto;
    z-index: 10;
    text-align: right;
    pointer-events: none;

    h3 {
        margin-top: 40px;
        margin-right: 15px;
        width: 75%;
        float: right;
        font-size: 20px;
        line-height: 25px;
    }
}

.homeshape {
    position: relative;
    width: 100%;
    height: 100vh;
    display: block;
    overflow: hidden;

    &:after {
        position: absolute;
        content: "";
        background-image: url(../../../public/shapes/AN_leaf_RGB_05.png);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0px;
        background-size: 900px;
        background-repeat: no-repeat;
        margin-left: 190px;
        width: 100%;
        background-position: right -100px;
    }
}


.main_login {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;

    .login {
        margin-top: 170px;
        display: flex;
        justify-content: left;
        align-items: center;
        width: 1170px;
        margin: 32px;

        article {
            background-color: $white;
            padding: 30px 30px 30px 30px;
            max-width: 400px;
        }
    }
}


form {
    .row {
        margin-bottom: 20px;

        &.row-radio {
            width: 100%;
            position: relative;

            input {
                width: auto;
                margin-right: 10px;
                border-radius: 5px;
            }

            label {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                >span {
                    padding-left: 20px;
                }
            }

            div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                display: inline-block;
                height: 40px;

                em {
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: white;
                    border: 1px $black solid;

                    span {
                        display: none;
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        width: 22px;
                        height: 22px;
                        background-color: $white;
                        border-radius: 50%;
                    }
                }

                &.active {
                    em {
                        span {
                            display: inline-block;
                            background-color: $magenta;
                        }
                    }
                }
            }
        }

        label {
            display: block;
            margin-bottom: 10px;
            font-family: $primary-font;
        }

        input,
        textarea {
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            padding: 8px 15px;
            font-family: $secondary-font;
            border-radius: 5px;
            background-color: $white;
            border: 1px $grey solid;

            &.react-date-picker__inputGroup__input {
                padding: 0 8px;
                border: 0;
                border-radius: 0;
                min-width: 25px;
            }

            &.react-date-picker__inputGroup__year {
                min-width: 50px !important;
            }
        }

        input[type="radio"] {
            -webkit-appearance: none;
            width: 25px;
            height: 25px;
            outline: none;
            border: 2px $black solid;
            border-radius: 100%;
            cursor: pointer;
            padding: 0;

            &:checked {
                &::after {
                    content: '';
                    position: absolute;
                    transform: translate(3px, 3px);
                    width: 15px;
                    height: 15px;
                    background: #000000;
                    border-radius: 100%;
                }
            }
        }

        .react-date-picker__wrapper {
            height: 42px;
            border: 1px $grey solid;
            border-radius: 5px;
        }

        select {
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            padding: 8px 15px;
            font-family: $primary-font;
            height: 43px;
            border: 1px $grey solid;
            background-color: $white;
            border-radius: 5px;
        }

        select,
        select:focus {
            background-image: url(../../../public/img/arrow-down.svg);
            background-size: 10px 10px;
            background-position: right 15px center;
            background-repeat: no-repeat;
            padding-right: 30px;
            appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
        }
    }

    .actions {

        p {
            float: left;
            margin-top: 15px;
            font-size: 16px;
        }
    }

    .login-notice {
        padding-top: 20px;

        strong {
            font-weight: bold;
        }

        p {
            margin-bottom: 0;
            font-size: 15px;
            line-height: 20px;

            a {
                color: $magenta;
            }
        }
    }

    input[type=checkbox] {
        height: 0px;
        position: relative;
        cursor: pointer;
    }

    input[type=checkbox]:before {
        top: 0px;
        left: 0;
        width: 25px;
        height: 25px;
        background: $white;
        border: 2px solid $black;
        position: absolute;
        content: '';
        border-radius: 100%;
    }

    input[type=checkbox]:checked::after {
        position: absolute;
        content: '';
        top: 5px;
        left: 5px;
        width: 15px;
        height: 15px;
        background: $black;
        border-radius: 100%;
    }



    .active {}

    input[type=checkbox]:before {
        top: 0px;
        left: 0;
        width: 25px;
        height: 25px;
        background: $white;
        border: 2px solid $black;
        position: absolute;
        content: '';
        border-radius: 100%;
    }

    .active:checked::after {
        background: $magenta !important;
    }
}

header {
    position: relative;
    width: 100%;
    height: 140px;
    z-index: 10;
}

.logo {
    position: absolute;
    top: 0;
    left: 30px;
    width: 120px;
    height: 140px;
    background-color: $white;
    display: flex;
    justify-content: center;

    a {
        width: 75%;
        vertical-align: middle;

        img {
            margin-top: 18px;
            display: block;
        }
    }
}

main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.overview-page {
    .overview-page-settings {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 2px solid #FFD800;
        padding-bottom: 30px;

        +.loader {
            margin-top: 30px;
        }

        .sort-button {
            justify-content: flex-end;
            display: inline-flex;
            flex-direction: row;
            align-items: center;

            p {
                margin-right: 16px;
                margin-bottom: 0;
            }

            select {
                width: auto;
                font-size: 16px;
                line-height: 24px;
                padding: 8px 15px;
                font-family: $secondary-font;
                height: 40px;
                border: 1px $grey solid;
                background-color: $white;
                border-radius: 5px;
            }

            select,
            select:focus {
                background-image: url(../../../public/img/arrow-down.svg);
                background-size: 10px 10px;
                background-position: right 15px center;
                background-repeat: no-repeat;
                padding-right: 30px;
                appearance: none;
                -webkit-appearance: none;
                cursor: pointer;
            }
        }
    }

    .results {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;

        &__item {
            color: $black;
            text-decoration: none;
            border-bottom: 2px solid $grey;
            padding: 10px 5px;
            width: 100%;
            height: auto;
            flex-wrap: nowrap;
            position: relative;
            display: inline-flex;
            cursor: pointer;

            &:hover {
                border-color: $magenta;
                background-color: $warm-grey;
            }

            &:hover:after {
                content: ">";
                margin-top: auto;
                right: 16px;
                position: absolute;
                top: 0;
                bottom: 0;
                height: auto;
                margin-bottom: auto;
                display: grid;
                align-items: center;
            }

            .result__img {
                background-color: $yellow;
                margin-right: 16px;
                width: 30px;
                height: 30px;
            }

            p {
                font-size: 16px;
            }

            b {
                font-size: 16px;
                margin-right: 16px;
            }



            .result__link {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                text-indent: -9999px;
            }
        }
    }

    .pager__container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 0 10px;

        span {
            color: $yellow;
            cursor: pointer;
            font-weight: 500;
            background-color: #222222;
            padding: 3px 14px;
            line-height: 30px;
        }
    }
}


.page {
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    background-color: $white;

    aside {
        padding-top: 170px;
        background-color: $warm-grey;
        max-width: 450px;
        min-width: 320px;

        nav {
            width: 100%;

            a {
                display: block;
                height: 60px;
                font-size: 20px;
                line-height: 30px;
                font-family: $primary-font;
                padding: 13px 30px 17px 30px;
                text-decoration: none;
                color: $black;
                border: 2px transparent solid;

                &.active {
                    background-color: $white;
                    border-bottom-color: $yellow;

                    &:after {
                        content: ">";
                        float: right;
                        margin-top: 2px;
                        display: block;
                    }
                }

                &:hover {
                    background-color: $white;
                    border-bottom-color: $magenta;

                    &:after {
                        content: ">";
                        float: right;
                        margin-top: 2px;
                        display: block;
                    }
                }
            }

            .whois {
                padding: 0 30px;
                margin-top: 60px;
                margin-bottom: 10px;
            }
        }
    }

    article {
        padding: 150px 30px 90px;
        min-height: 100vh;
        margin-right: 0;
        max-width: 1170px;
        margin-left: 5%;
    }
}

.callreport-form-page {

    article {
        >.row {
            margin: auto 0;
        }
        .callreport__title{
            line-height: 55px;
            border-bottom: 2px $yellow solid;
            padding-bottom: 15px;
            margin-bottom: 30px;
    }

        .results {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 40px;

            .result {
                border: 2px solid #000;
                padding: 32px;
            }
        }

        .callreport-form {
            hr {
                border-top: 2px solid black;
                margin: 16px 0 32px;

                &.no-margin {
                    margin: 0;
                }
            }

            h3 {
                padding-bottom: 32px;
                p.error {
                    margin-top: 0; 
                    position: absolute;
                }
                i {
                    font-weight: normal;
                    font-size: 16px;
                    margin-left: 5px;
                }
            }

            h3:nth-of-type(7) {
                padding-top: 16px;
            }

            fieldset {

                >div {
                    display: grid;
                    margin: 0;
                    grid-template-columns: 1fr 1fr 1fr;
                    width: 100%;
                    justify-content: center;
                    grid-gap: 10px 30px;
                }
            }

            .volunteer {
                &__containter {
                    grid-template-columns: 0.3fr 1fr;

                    .callback__containter {
                        position: relative;
                        bottom: 15px;
                    }
                }
            }

            .time-of-day {
                &__containter {
                    grid-template-columns: 1fr 1fr 1fr;

                    .react-date-picker {
                        width: 100%;


                    }

                }
            }

            .caller {
                &__containter {
                    grid-template-columns: 1fr 1fr;
                }
            }

            .about-person {
                &__containter {
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }

            .substance {
                margin-bottom: 30px;
                margin-top: 5px;
                
                &__containter {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 40px;

                    label {
                        display: inline-flex;
                        position: relative;

                        >span {
                            padding-left: 20px;
                        }

                    }
                }
            }

            .description {
                &__containter {
                    grid-template-columns: 1fr;
                }

                textarea {
                    height: 175px;
                }
            }

            .callback {
                margin-bottom: 30px;
                bottom: 15px;
                position: relative;

                &__containter {
                    display: inline-block !important;
                    margin: unset !important;
                    /* width: 320px !important; */
                    grid-template-columns: none;

                    label {
                        display: inline-flex;
                        position: relative;

                        >span {
                            padding-left: 20px;
                        }

                    }

                }
            }

            .subject-details {
                &__containter {
                    grid-template-columns: 1fr;

                    textarea {
                        height: 120px;
                    }
                }
            }

            .actions {
                display: inline-flex;
                flex-wrap: wrap;
                
                button {
                    max-width: 160px;
                }
            }
        }
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {

    opacity: 1;

}

.react-time-picker {
    width: 100%;

    &__inputGroup__input {
        height: auto !important;
        border: none !important;
    }

    &__clock {
        display: none;
    }

}

.margin-top {
    margin-top: 32px;
}



//temporarily fix for time input

input[type="time"] {
    visibility: unset !important;
    position: unset !important;
    top: 0px !important;
    left: 0px !important;
    border: none !important;
    cursor: text;
    border-radius: 5px !important;
}

input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour,
.react-time-picker__inputGroup__divider,
span.react-time-picker__inputGroup__leadingZero,
.react-time-picker__inputGroup__input--hasLeadingZero,
.react-time-picker__inputGroup__input {
    display: none;
}


.info-callreport {
    position: fixed;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: grid;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;

    &__container {
        max-width: 840px;
        background-color: $white;
        padding: 30px;
        border-radius: 5px;
        position: relative;
        margin: 50px;

        .close-icon {
            position: absolute;
            right: -40px;
            top: -59px;
            width: 40px;
            cursor: pointer;
        }

        h3 {
            margin-bottom: 20px;
        }

        ul {
            list-style: disc;
            margin-left: 26px;

            p {
                font-size: 16px;

                b {
                    font-size: inherit;
                }
            }
        }
    }
}

.info__button {
    background-color: #f1f0e8;
    position: fixed;
    right: 30px;
    bottom: 20px;
    width: 75px;
    height: 75px;
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    border-radius: 100%;
    z-index: 999;
    color: $black;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: $warm-grey-hover;
    }
}

.loader {
    @keyframes spin {
        0% {
            transform: rotate(45deg);
        }

        100% {
            transform: rotate(405deg);
        }
    }

    width: 50px;
    height: 50px;
    border: 5px solid $warm-grey;
    border-top-color: $yellow;
    border-radius: 100%;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.loader-container {
    position: fixed;
    top: 0%;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    justify-items: center;
    z-index: 999;

    .loader {
        @keyframes spin {
            0% {
                transform: rotate(45deg);
            }

            100% {
                transform: rotate(405deg);
            }
        }

        width: 50px;
        height: 50px;
        border: 5px solid #F1F0E8;
        border-top-color: #FFD800;
        border-radius: 100%;
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        position: relative;
        transform: translateY(50%);
        top: 50%;
    }
}


.loader-container::after {
    content: "";
    position: absolute;
    background-color: rgba($black, .3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

p.error {
    font-size: 14px;
    margin-top: 5px;
    color: $red;
}

.contact-input__container {
    width: 80%;
	margin: 0 auto;

    tbody {
        .radio-cell {
            display: flex;
            align-items: center;
            text-align: right;
        }

        tr:last-child {
            td {
                border: none;
            }
        }

        td {
            padding-bottom: 20px;
            border-bottom: 1px solid #000;
            padding-top: 20px;
            border-color: rgb(171, 171, 171);

            

            label { 
                display: inherit;
                margin: 0 10px;
            }
        
            // input[type="radio"] {
            //     width: auto;
            // }
        }
    }
}