

.info__button-enter {
	opacity: .001;
	will-change: opacity;
}
.info__button-enter-active {
	opacity: 1;
	transition: 300ms opacity;
}
.info__button-enter-done {
	opacity: 1;
}
.info__button-exit {
	opacity: 1;
	will-change: opacity;
}
.info__button-exit-active {
	opacity: .001;
	transition: 300ms opacity;
}
.info__button-exit-done {
	opacity: .001;

}
.callback__open-enter {
	opacity: .001;
	will-change: opacity;
}
.callback__open-enter-active {
	opacity: 1;
	transition: 300ms opacity;
}
.callback__open-enter-done {
	opacity: 1;
}
.callback__open-exit {
	opacity: 1;
	will-change: opacity;
}
.callback__open-exit-active {
	opacity: .001;
	transition: 300ms opacity;
}
.callback__open-exit-done {
	opacity: .001;

}
