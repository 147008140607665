@use 'settings';

@media(max-width: #{$screen_monitor}) {}

@media(max-width: #{$screen_desktop}) {}

@media(max-width: #{$screen_laptop}) {
    .callreport-form-page {
        article {
            .callreport-form {
                .volunteer {
                    &__containter {
                        grid-template-columns: 0.3fr 1fr;
                    }
                }

                .time-of-day {
                    &__containter {
                        grid-template-columns: 1fr 1fr;
                    }
                }

                .caller {
                    &__containter {
                        grid-template-columns: 1fr;
                    }
                }

                .about-person {
                    &__containter {
                        grid-template-columns: 1fr 1fr 1fr;
                    }
                }

                .substance {
                    &__containter {
                        grid-template-columns: 1fr 1fr 1fr;
                    }
                }

                .description {
                    &__containter {
                        grid-template-columns: 1fr;
                    }
                }

                .contact-details {
                    &__containter {
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-gap: 10px 40px;
                    }
                }
            }
        }
    }
}

@media(max-width: #{$screen_laptop_small}) {
    .whois {
        display: none;
    }

    .page {
        grid-template-columns: auto;
        grid-template-rows: 1fr;

        aside {
            max-width: 100%;
            min-width: 100%;

            nav {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                a{
                    width: 100%;
                }
            }
        }

        article {
            max-width: 100%;
            padding: 30px ;
            margin-left: unset;
        }
    }

    .page aside nav a:hover:after {
        display: none;
    }

    .page aside nav a:hover {
        background-color: unset;
        border-bottom-color: #EC407A;
    }

    .homeshape {
        &:after {
            background-size: 540px;
        }
    }
}


@media(max-width: #{$screen_tablet}) {
    .overview-page {
        .overview-page-settings {

            grid-template-columns: 1fr;

            .sort-button {
                justify-content: flex-start;
                margin-top: 16px;
            }
        }
    }

    .callreport-form-page {
        article {
            .callreport-form {
                fieldset {
                    >div {
                        grid-template-columns: 1fr !important;
                    }
                }
            }
        }
    }
}


@media(max-width: #{$screen_mobile}) {
    h1 {
        font-size: 33px;
    }

    h2 {
        font-size: 25px;
    }

    h3 {
        font-size: 20px;
    }

    .homeshape {
        &:after {
            display: none;
        }
    }

    .page {
        article {
            .results {
                &__item {
                    border-bottom: 2px solid #F1F0E8;
                    padding: 5px;
                    width: 100%;
                    height: auto;
                    flex-wrap: nowrap;
                    position: relative;
                    display: inline-flex;
                    grid-gap: 5px;
                    flex-direction: column;
                }
            }
        }
    }

    .info-callreport {

        &__container {
            padding: 15px;
            margin: 5px;

            .close-icon {
                display: none;
            }

            h3 {
                margin-bottom: 0px;
                font-size: 16px;
            }

            ul {
                list-style: disc;
                margin-left: 16px;

                p {
                    font-size: 12px;
                }
            }
        }
    }

    .info__button {
        right: 15px;
        bottom: 15px;
        width: 50px;
        height: 50px;
        font-size: 24px;
    }
}

@media(max-width: #{$screen_mobile_small}) {
    .headershape {
        display: none
    }

    .main_login {
        .login {
            margin: 170px 32px 32px;
        }
    }
}

@media(max-width: #{$screen_mobile_smaller}) {}