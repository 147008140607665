@use 'settings';

@font-face {
    font-display: swap;
    font-family: 'Raisonne_an';
    src: url('../../../public/fonts/Raisonne-An.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'OpenSans';
    src: url('../../../public/fonts/OpenSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'OpenSans';
    src: url('../../../public/fonts/OpenSans-SemiBold.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'OpenSans';
    src: url('../../../public/fonts/OpenSans.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Verdana';
    src: url('../../../public/fonts/Verdana.ttf');
    font-weight: 400;
    font-style: normal;
}


$primary-font: Raisonne_an, sans-serif;
$secondary-font: OpenSans, sans-serif;

.nav__item {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: settings.$black;
}

h1 {
    font-size: 40px;
    line-height: 48px;
    font-family: $primary-font;
    letter-spacing: -.5px;
    font-weight: 400;
}

h2 {
    font-size: 20px;
    line-height: 28px;
    font-family: $primary-font;
    font-weight: 400;
}

h3 {
    font-size: 20px;
    font-family: $primary-font;
    font-weight: 400;
}

b {
    font-family: $secondary-font;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
}

p {
    font-family: $secondary-font;
    font-size: 20px;
    line-height: 28px;
}

.link {
    text-decoration: underline;
    font-size: 16px;
}

.button {
    position: relative;
    
    font-size: 18px;
    font-weight: 500;
}
